import React from 'react';
import ProductListing, {Product} from '../../../react4xp/components/ProductListing';


interface FeaturedListingsProps {
	heading?: string
	listings?: Product[]
	viewAllPage: string // href
}


function FeaturedListings({
	heading,
	listings,
	viewAllPage
}: FeaturedListingsProps) {
	return (
		<section className="featured-listings">
		{ heading && viewAllPage && (
			<header className="featured-listings__header">
				<a className="featured-listings__view-all-link" href={viewAllPage}>
					<h2 className="featured-listings__heading">{ heading }</h2>
					<span className="featured-listings__view-all">{`View all ${heading}`}</span>
				</a>
			</header>
		)}
		{ listings && <ProductListing products={listings} /> }
		</section>
	);
}


export default (props: FeaturedListingsProps) => <FeaturedListings {...props} />;
